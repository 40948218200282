<template>
  <a :href="link" :class="`button ${icon}`" :onClick="onClick">
    <i :class="`${iconStyle} fa-${icon} ${iconPosition}`" v-if="icon !== null && iconPosition === 'left'"></i>
    <slot></slot>
    <i :class="`${iconStyle} fa-${icon} ${iconPosition}`" v-if="icon !== null && iconPosition === 'right'"></i>
  </a>
</template>

<script>
export default {
  name: "Button",
  props: {
    icon: {
      type: String,
      default: null
    },
    iconStyle: {
      type: String,
      default: "fas"
    },
    iconPosition: {
      type: String,
      default: "left"
    },
    link: {
      type: String,
      default: null
    },
    onClick: {
      type: Function,
      default: () => {}
    }
  }
}
</script>

<style lang="scss" scoped>
a.button {
  background-color: var(--color-bg-light);
  border-color: var(--color-bg-light);
  padding: 10px 1rem;
  cursor: pointer;

  i.left {
    margin-right: 8px;
  }

  i.right {
    margin-left: 8px;
  }

  &:hover, &:focus {
    background-color: var(--color-bg-light);
    border-color: var(--color-bg-light);
  }
}</style>