<template>
  <section class="game">
    <iframe frameborder="0" :src="`${game.playLink}?color=292835`" allowfullscreen="" width="1330" height="750"></iframe>
  </section>
</template>

<script>
import Button from '@/components/ui/Button.vue'

export default {
  name: "GamesShowcasePlay",
  components: {
    Button
  },
  data() {
    return {
      games: this.$store.state.games,
    }
  },
  computed: {
    game() {
      const allGames = this.$store.state.games
      const gameId = parseInt(this.$route.params.gameId)
      const game = allGames.find(g => g.id === gameId)
      return game
    }
  },
  methods: {
  }
};
</script>

<style scoped lang="scss">
.game iframe {
  border-radius: 1rem;
  border: 2px solid #444455;
  width: 100%;
}
</style>
